
import { defineComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { AppMain, AppFooter, AppHeader } from './components'
import { useStore } from '@/store'

export default defineComponent({
  name: 'Layout',
  components: {
    AppMain,
    AppHeader,
    AppFooter
  },
  setup() {
    const { t } = useI18n()
    const store = useStore()

    onMounted(() => {
      const el = window.document.querySelector('.J_Wrapper') as HTMLElement
      if (store.state.app.device === 2 && el) {
        el.classList.add('w-wrapper')
      }
      const timer = window.setTimeout(() => {
        if (el) el.style.visibility = 'visible'
        window.clearTimeout(timer)
      }, 300)
    })

    return {
      t
    }
  }
})
