/* eslint-disable */

export default {
  route: {
    home: 'Home Page'
  },
  nav: {
    demoGame: 'Demo Games',
    contactUs: 'Contact Us',
    ourAdvantage: 'FG Advantages',
    hotGame: 'Hot Games'
  },
  home: {
    pageTitle: 'Leyou — unlimited fun and win-win wealth',
    legend: 'Please leave your contact infomation',
    submitSuccess: 'Submitted successfully',
    nameTips: 'name is rquired',
    name: 'name',
    phone: 'phone',
    submit: 'submit',
    email: 'email',
    emailTips1: 'email is required',
    emailTips2: 'email format is incorrect',
    information: 'message',
    phoneTips: 'phone number format is incorrect',
    fgGaming: 'Fungaming (FG), a leading electronic entertainment supplier in Asia, has produced four major products, namely FG Hunting games, Chess games, Slot games and Arcade games with its corporate culture of “distinctiveness”.  More than 200 kinds of featured games are developed; with its standardized development process and perfect customer service, it has rapidly evolved into a top game supplier in the Asian market',
    securityTeam: 'The most security protection and first-class expert team',
    cooperateWithYou: 'we sincerely expect to cooperate with you',
    copyright: 'copyright © {year} FunGaming reserve all the rights',
    fgAdheres: 'FG adheres to the tenet of More Fun, More Rewards, builds a bridge of communication for valued customers, and is assisted by a professional team to solve your questions and needs',
    shareGame: 'Share the hot games with you',
    focusGame: 'We are focus on the quality of FG games',
    html5Teconology: '\"HTML5 Teconology\"',
    fgGame: 'FG Game',
    perfect: 'are perfectly',
    compatibel: 'compatible',
    device: 'with a variety of smart terminal devices',
    supportScreen: 'Support horizontal and vertical screen adaptation and free switching',
    figure: '\"Figures',
    and: ' And ',
    fluctuation: 'Fluctuations\"',
    rtpSetting: 'Reasonable and stable RTP numerical logic setting',
    adjust: 'Adjust',
    fluctuations: 'the fluctuations',
    achieve: 'to achieve',
    balance: 'optimal balance',
    experience: '\"Extreme Game Experience\"',
    perfectSound: 'The perfect combination of dynamic and sound effects',
    multiNode: 'Multi-node',
    deployment: 'deployment',
    for: 'for',
    optimal: 'optimal',
    performance: 'performance and',
    extreme: 'extreme',
    expery: 'experience',
    customizedService: '\"Customized Service\"',
    playerPreference: 'According to player preferences or customer needs',
    provide: 'Provide',
    multiCurrency: 'language multi-currency、multi-player online、wallet、etc',
    customized: 'customized services',
    access: '\"Access',
    accessAnd: 'And',
    service: 'Service\"',
    flexibleAPI: 'Flexible and simple API integration access',
    flexibleProvide: 'Provide',
    provide7x24: '7x24 uninterrupted',
    customerService: 'customer service consultation and',
    technicalSupport: 'technical support',
    data: '\"Data',
    dataAnd: ' And ',
    risk: 'Risk Management\"',
    professional: 'Professional data analysis',
    improves: 'improves',
    efficiency: 'operational efficiency',
    multiDimensional: 'Multi-dimensional risk management ensures',
    safe: 'safe',
    operation: 'operation of the game'
  },
  hotDetail: {
    rate: 'resolution',
    supportLanguage: 'support language',
    browser: 'browser',
    platform: 'platform',
    line: 'line',
    realeaseTime: 'release date',
    gameType: 'game category',
    gameName: 'game name',
    feature: 'game features'
  }
}
