/* eslint-disable */

export default {
  route: {
    home: 'Home Page'
  },
  nav: {
    demoGame: 'Thử chơi',
    contactUs: 'Liên hệ chúng tôi',
    ourAdvantage: 'FG Lợi thế',
    hotGame: 'Trò chơi phổ biến'
  },
  home: {
    pageTitle: 'Fungaming - Niềm vui bất tận Win-Win Wealth',
    legend: 'Vui lòng để lại thông tin liên lạc',
    submitSuccess: 'Nộp thành công',
    nameTips: 'Tên là bắt buộc',
    name: 'Tên',
    phone: 'Di động',
    submit: 'Giới thiệu',
    email: 'Thư điện tử',
    emailTips1: 'Email thì cần thiết',
    emailTips2: 'Định dạng email không chính xác',
    information: 'Tin tức',
    phoneTips: 'Định dạng số điện thoại di động không chính xác',
    fgGaming: 'Fungaming（FG）， Nhà cung cấp giải trí trò chơi điện tử hàng đầu châu Á, FG đã tạo ra bốn dòng sản phẩm chính của FG Hunting, FG Board, FG Slot Machine, FG Arcade với tư duy đổi mới đa dạng, tích lũy R&D hơn hai trăm trò chơi đặc trưng, và với quy trình R&D nghiêm ngặt và tiêu chuẩn hóa và hệ thống hoạt động dịch vụ khách hàng hoàn hảo, nhanh chóng phát triển thành nhà cung cấp trò chơi hàng đầu trên thị trường châu Á.',
    securityTeam: 'Bảo vệ an toàn nhất và đội ngũ chuyên gia hạng nhất',
    cooperateWithYou: 'Chúng tôi chân thành mong muốn hợp tác với bạn',
    copyright: 'Bản quyền © 2019 FunGaming Tất cả các quyền',
    fgAdheres: 'Với phương châm "Vui vẻ hơn, thưởng thức nhiều hơn", FG xây dựng cầu nối giao tiếp cho khách hàng có giá trị và được hỗ trợ bởi một đội ngũ chuyên nghiệp để giải quyết các vấn đề và nhu cầu của bạn',
    shareGame: 'Chia sẻ các trò chơi nóng với bạn',
    focusGame: 'Chúng tôi tập trung vào chất lượng của trò chơi FG',
    html5Teconology: 'Công nghệ HTML5',
    fgGame: 'FG Gaming',
    perfect: 'Hoàn hảo',
    compatibel: 'tương thích',
    device: 'Thiết bị đầu cuối thông minh khác nhau',
    supportScreen: 'Hỗ trợ điều chỉnh màn hình ngang và dọc và chuyển đổi miễn phí',
    figure: '\"giá trị',
    and: 'Và',
    fluctuation: 'dao động\"',
    rtpSetting: 'Thiết lập logic kỹ thuật số RTP ổn định hợp lý',
    adjust: 'tính đến',
    fluctuations: 'biến động trò chơi',
    achieve: 'đạt được',
    balance: 'cân bằng tốt nhất',
    experience: '\"Trải nghiệm chơi game đỉnh cao\"',
    perfectSound: 'Sự kết hợp hoàn hảo giữa chuyển động và hiệu ứng âm thanh',
    multiNode: 'đa nút',
    deployment: 'triển khai',
    for: 'hoàn thành',
    optimal: 'tốt nhất',
    performance: 'hiệu suất và',
    extreme: 'vô cùng',
    expery: 'trải nghiệm',
    customizedService: '\"Dịch vụ tùy chỉnh\"',
    playerPreference: 'Theo sở thích của người chơi hoặc nhu cầu của khách hàng',
    provide: 'cung cấp',
    multiCurrency: 'Ngôn ngữ, tiền tệ, đa mở, ví, v.v.',
    customized: 'dịch vụ tùy chỉnh',
    access: '\"truy cập',
    accessAnd: 'Và',
    service: 'Phục vụ\"',
    flexibleAPI: 'Truy cập tích hợp API linh hoạt và dễ dàng',
    flexibleProvide: 'cung cấp',
    provide7x24: '7×24 không bị gián đoạn',
    customerService: 'Tư vấn dịch vụ khách hàng và',
    technicalSupport: 'Hỗ trợ kỹ thuật',
    data: '\"dữ liệu',
    dataAnd: 'Và',
    risk: 'kiểm soát gió\"',
    professional: 'Phân tích dữ liệu chuyên nghiệp',
    improves: 'khuyến khích',
    efficiency: 'Hiệu quả hoạt động',
    multiDimensional: 'Quản lý rủi ro đa chiều đảm bảo an toàn Trò chơi hoạt động',
    safe: 'Sự an toàn',
    operation: 'vận hành'
  },
  hotDetail: {
    rate: 'nghị quyết',
    supportLanguage: 'hỗ trợ ngôn ngữ',
    browser: 'trình duyệt',
    platform: 'nền tảng',
    line: 'Số dòng',
    realeaseTime: 'ngày phát hành',
    gameType: 'danh mục trò chơi',
    gameName: 'tên của trò chơi',
    feature: 'Tính năng trò chơi'
  }
}
