/*
 * @Description: app
 */
import https from '@/utils/https'
import { RootObject } from '@/model/rootObject'
import { ContentType, Method } from 'axios-mapper'
import { LanguageItem, PersonItem } from '@/model/appModel'
import { Person } from '@/model/app'

// 获取信息与配置
export const getPerson = () => {
  return https().request<RootObject<Person<PersonItem>>>('api/msgBoard/person', Method.GET, undefined, ContentType.form)
}

// 获取语言列表
export const getLanguage = () => {
  return https().request<RootObject<LanguageItem>>('api/culture/showList', Method.GET, undefined, ContentType.form)
}
