
import { computed, defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import { useStore } from '@/store'
import { AppActionTypes } from '@/store/modules/app/action-types'
import logo from '@/assets/images/header_logo.png'
import CountryFlag from 'vue-country-flag-next'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    CountryFlag
  },
  setup() {
    const { t, locale } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const isMobile = computed(() => store.state.app.device === 1)
    const isRoute = computed(() => route.path === '/list' || route.path === '/hotDetail' ||
      route.path === '/carouselDetail')
    const hasLangSelect = computed(() => route.path !== '/hotDetail' && route.path !== '/carouselDetail')
    const dataMap = reactive({
      lang: store.state.app.language,
      target: '.J_Home'
    })
    const languageList = computed(() => store.state.app.languageList)
    const methods = reactive({
      onChange(value: string) {
        locale.value = value.toLowerCase()
        store.dispatch(AppActionTypes.ACTION_SET_LANGUAGE, value)
        window.document.title = t('home.pageTitle')
      },
      clickMenu(e: any) {
        e.stopPropagation()
      },
      clickLogo() {
        const el = window.document.querySelector('.J_Menu')
        const arrow = window.document.querySelector('.J_Logo-arrow') as HTMLElement
        if (el) {
          el.classList.toggle('menu-show')
          if (el.classList.contains('menu-show')) arrow.style.display = 'none'
          else arrow.style.display = 'flex'
        }
      },
      setActive(n: number) {
        const menuList = window.document.querySelectorAll('.J_Menu-item li') as any
        for (let i = 0, len = menuList.length; i < len; i++) {
          menuList[i].classList.remove('active')
        }
        if (menuList) menuList[n].classList.add('active')
      },
      handleClick(name: string) {
        dataMap.target = name
        window.sessionStorage.menu = name.substring(3)
        switch (name) {
          case '.J_Game':
            methods.setActive(0)
            methods.clickLogo()
            break
          case '.J_Contact':
            methods.setActive(1)
            break
          case '.J_Advantage':
            methods.setActive(2)
            break
          case '.J_Hot':
            methods.setActive(3)
            break
          case '.J_Home':
            methods.setActive(4)
            break
          default:
            methods.setActive(4)
        }
        if (name === '.J_Game') {
          router.push('/game')
        } else {
          router.push('/home').then(() => {
            const el = window.document.querySelector(name)
            if (el) el.scrollIntoView()
          })
        }
      },
      handleArrow(e: any) {
        e.stopPropagation()
        methods.clickLogo()
      },
      onBack() {
        if (route.path === '/hotDetail' || route.path === '/carouselDetail') router.push('/home')
        if (route.path === '/list') router.push('/game')
      }
    })

    onMounted(() => {
      const el = window.document.querySelector('.J_Header')
      if (store.state.app.device === 1 && el) {
        el.classList.add('m-header')
      }
      // eslint-disable-next-line
      window.document.querySelector('body')?.addEventListener('click', () => {
        const el = window.document.querySelector('.J_Menu')
        const arrow = window.document.querySelector('.J_Logo-arrow') as HTMLElement
        if (el) {
          el.classList.remove('menu-show')
          arrow.style.display = 'flex'
        }
      })
      if (route.path === '\/' || route.path === '/home') {
        methods.setActive(4)
        window.sessionStorage.menu = 'Home'
      } else if (route.path === '/game' || route.path === '/list') {
        methods.setActive(0)
        window.sessionStorage.menu = 'Game'
      } else if (route.path === '/hotDetail') {
        methods.setActive(3)
        window.sessionStorage.menu = 'Hot'
      } else if (route.path === '/carouselDetail') {
        window.sessionStorage.menu = ''
      }

      const menuList = window.document.querySelectorAll('.J_Menu-item li') as any
      for (let i = 0, len = menuList.length; i < len; i++) {
        if (menuList[i]) {
          menuList[i].onmouseenter = (e: any) => {
            e.target.classList.add('active')
            dataMap.target = `.J_${window.sessionStorage.menu}`
          }
          menuList[i].onmouseleave = (e: any) => {
            const key = e.target.dataset.key
            if (route.path === '\/' || route.path === '/home') {
              if (dataMap.target.includes(`.J_${key}`)) return
              else if (window.sessionStorage.menu && window.sessionStorage.menu === key) return
            } else if ((route.path === '/game' || route.path === '/list') && key === 'Game') {
              return
            } else if ((route.path === '/hotDetail') && key === 'Hot') {
              return
            }
            e.target.classList.remove('active')
          }
        }
      }
    })

    watch(() => route.path, () => {
      // if ((route.path === '\/' || route.path === '/home') && dataMap.target) {
      //   window.setTimeout(() => {
      //     const el = window.document.querySelector(dataMap.target)
      //     if (el) el.scrollIntoView()
      //     dataMap.target = ''
      //   }, 500)
      // }
    })

    return {
      t,
      logo,
      languageList,
      isMobile,
      isRoute,
      hasLangSelect,
      ...toRefs(dataMap),
      ...toRefs(methods)
    }
  }
})
